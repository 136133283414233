import { lazy } from 'react'

const Appointment = lazy(() => import('@app/appointment/appointment'))

const AppointmentCompleted = lazy(
  () => import('@app/appointment-completed/appointment-completed'),
)
const NewCalendar = lazy(() => import('@app/new-calendar/new-calendar'))
const Home = lazy(() => import('@app/home/home'))
const Login = lazy(() => import('@app/login/login'))
const MedicalFile = lazy(() => import('@app/medical-file/medical-file'))
const PatientAppointment = lazy(
  () => import('@app/patient-appointment/patient-appointment'),
)
const PatientDetails = lazy(
  () => import('@app/patient-details/patient-details'),
)
const Patients = lazy(() => import('@app/patients/patients'))
const Profile = lazy(() => import('@app/profile/profile'))
const SignUp = lazy(() => import('@app/sign-up/sign-up'))
const PreAppointment = lazy(
  () => import('@app/pre-appointment/pre-appointment'),
)
export const Pages = [
  {
    path: '/',
    isPrivate: false,
    Component: Login,
    title: 'Login',
  },
  {
    path: '/cadastro',
    isPrivate: false,
    Component: SignUp,
    title: 'Cadastro',
  },
  {
    path: '/agenda',
    isPrivate: true,
    Component: NewCalendar,
    title: 'Agenda',
  },
  {
    path: '/perfil',
    isPrivate: true,
    Component: Profile,
    title: 'Perfil',
  },
  {
    path: '/pacientes',
    isPrivate: true,
    Component: Patients,
    title: 'Pacientes',
  },
  {
    path: '/pacientes/:id',
    isPrivate: true,
    Component: PatientDetails,
    title: 'Informações dos pacientes',
  },
  {
    path: '/pacientes/:id/consultas/:id',
    isPrivate: true,
    Component: PatientAppointment,
    title: 'Consulta',
  },
  {
    path: '/pacientes/:id/ficha',
    isPrivate: true,
    Component: MedicalFile,
    title: 'Ficha médica',
  },
  {
    path: '/consulta/:id',
    isPrivate: true,
    Component: Appointment,
    title: 'Consulta',
  },
  {
    path: '/pre-consulta/:id',
    isPrivate: true,
    Component: PreAppointment,
    title: 'Pré-consulta',
  },
  {
    path: 'home',
    isPrivate: true,
    Component: Home,
    title: 'Home',
  },
  {
    path: 'consulta-concluida',
    isPrivate: true,
    Component: AppointmentCompleted,
    title: '',
  },
]
